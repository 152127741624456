<template>
  <q-dialog
    v-model="dialogOpen"
    @ok="setAssignee"
    @cancel="() => newAssignee = null"
  >
    <q-card>
      <q-card-section>
        <div class="text-h5 q-pb-md">
          Assign to SDC colleague
        </div>
        <q-item-label caption>
          Start typing and select from the dropdown.
        </q-item-label>
        <q-item-label caption>
          Not appearing? They may not have been added to the system or could be blocked
        </q-item-label>
        <m-select-query
          v-model="newAssignee"
          label="Set Assignee"
          placeholder="Search"
          icon="search"
          :query="autocomplete"
        />
      </q-card-section>
      <q-card-actions align="right">
        <q-btn
          v-if="request && request.assignee"
          color="faded"
          label="Unassign"
          @click="unassign"
        />
        <q-btn
          v-close-popup
          color="primary"
          label="Set Assignee"
          @click="setAssignee"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
import { index as searchStaff } from 'api/backoffice'
import { MSelectQuery } from 'components/'

export default {
  components: { MSelectQuery },
  props: ['value'],
  data () {
    return {
      newAssignee: null,
      users: []
    }
  },
  computed: {
    dialogOpen: {
      get () {
        return !!this.value
      },
      set (val) {
        if (!val) {
          this.$emit('input', val)
        }
      }
    },
    request () {
      return this.$store.getters['entities/requests/find'](this.value)
    }
  },
  watch: {
    request (val) {
      this.newAssignee = val && val.assignee
        ? {
          label: this.request.assignee.display_name,
          value: this.request.assignee.username
        }
        : null
    }
  },
  methods: {
    autocomplete (val) {
      return searchStaff({ search: val })
        .then((users) => {
          return users.data.map(u => ({
            label: u.display_name,
            value: u,
            avatar: u.picture
          }))
        })
    },
    unassign () {
      this.$store.dispatch('entities/requests/unassign', { requestId: this.value })
        .then(() => {
          this.dialogOpen = false
          this.$q.notify({
            message: `User unassigned`,
            color: 'positive',
            icon: 'mdi-check'
          })
        })
        .catch(e => {
          this.$q.notify({
            message: `User not unassigned, error occurred`,
            color: 'negative',
            icon: 'clear'
          })
        })
    },
    setAssignee () {
      if (!this.newAssignee.value) return
      this.$store.dispatch('entities/requests/setAssignee', {
        requestId: this.value,
        user: this.newAssignee.value
      })
        .then(() => {
          this.dialogOpen = false
          this.$q.notify({
            message: `User assigned`,
            color: 'positive',
            icon: 'mdi-check'
          })
        })
        .catch(e => {
          this.$q.notify({
            message: `User not assigned, error occurred`,
            color: 'negative',
            icon: 'clear'
          })
        })
    },
    cancel () {
      this.newAssignee = null
      this.dialogOpen = false
    }
  }
}
</script>

<style lang="stylus" scoped>
.modal-scroll
  min-height 80px

sub
  display block
  margin-bottom 16px

</style>

<style lang="stylus">
.modal-scroll
  min-height 80px

</style>
