<template>
  <m-select-query
    v-model="requester"
    v-bind="$attrs"
    label="Requester"
    :query="getRequesters"
    :error="error"
    :error-message="errorMessage"
    clearable
  />
</template>

<script>
import { search } from 'api/users'
import { costCentres } from 'api/organisations'
import shapeUser from './shape-user'
import { MSelectQuery } from 'components/'

export default {
  components: { MSelectQuery },
  props: {
    value: Object,
    error: Boolean,
    errorMessage: String
  },
  computed: {
    requester: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
        this.getCostCentres(val.organisation)
      }
    }
  },
  methods: {
    getRequesters (string) {
      return search({
        query: string
      })
        .then(response => {
          const displayRoles = ['user', 'member', 'middleoffice']
          const users = response.data.filter(u => u.roles.some(r => displayRoles.includes(r)))
          return users.map(user => shapeUser(user))
        })
    },
    getCostCentres (organisation) {
      return costCentres(organisation)
        .then(response => {
          this.$emit('requesterSelected', response)
        })
    }
  }
}
</script>

<style>

</style>
