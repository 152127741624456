<template>
  <q-page class="layout-padding">
    <cancel-form :filled-requester="requester" />
  </q-page>
</template>

<script>
import cancelForm from '../forms/cancel-form'
export default {
  components: {
    cancelForm
  },
  props: {
    requester: Object
  }
}
</script>

<style>

</style>
