export default function (invoiceItems) {
  return {
    items: invoiceItems.map(i => {
      return {
        quantity: i.quantity,
        description: i.description,
        unit_price: i.unit_price,
        tax_rate: i.tax_rate,
        tax_country: 'GB',
        currency: 'GBP',
        is_mobilleo_fee: i.is_mobilleo_fee,
        unit_price_includes_tax: i.unit_price_includes_tax
      }
    })
  }
}
