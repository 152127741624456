<template>
  <div class="row q-col-gutter-sm">
    <div class="col-sm-6">
      <m-date-field
        :value="value"
        :label="label"
        format="dd/LL/yy"
        @change="datePicked"
      />
    </div>
    <div class="col-sm-6">
      <m-time-field
        :minute-step="5"
        :value="value"
        label="Time"
        @change="datePicked"
      />
    </div>
  </div>
</template>

<script>
import { MTimeField, MDateField } from 'components/'
export default {
  components: {
    MTimeField,
    MDateField
  },
  props: {
    label: {
      type: String,
      default: 'Select a date and time'
    },
    value: [Object, Date, String],
    error: Boolean
  },
  methods: {
    datePicked (value) {
      this.$emit('input', value)
    }
  }
}
</script>

<style>

</style>
