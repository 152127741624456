<template>
  <div class="col-sm-12 row q-col-gutter-sm">
    <div v-if="options.length" class="col-12 col-sm">
      <q-select
        v-model="reference"
        :options="options"
        label="Add to existing journey"
        map-options
        emit-value
        stack-label
        filter
        autofocus-filter
        @input="change"
      >
        <template v-slot:append>
          <q-icon
            v-if="reference !== null"
            class="cursor-pointer"
            name="clear"
            @click.stop="reset"
          />
        </template>
      </q-select>
    </div>
    <div v-else-if="username" class="col-12 column unavailable-journey-text">
      No existing journeys available to attach to, create a new one below
    </div>
    <div v-else class="col-12 column unavailable-journey-text">
      Select a traveller to attach to an existing journey
    </div>
    <div v-if="!username || options.length" class="col-12" :class="{ 'col-sm-2': options.length }">
      <h5 class="text-faded">
        Or
      </h5>
    </div>
    <div class="col-12 col-sm">
      <q-input
        v-model="name"
        :max-height="150"
        :min-rows="1"
        type="text"
        label="Create a new journey"
        stack-label
        @change.native="change"
      />
    </div>
  </div>
</template>

<script>
import { existingJourneys } from 'api/user'
export default {
  props: {
    color: String,
    username: String
  },
  data () {
    return {
      name: null,
      reference: null,
      options: []
    }
  },
  watch: {
    username () {
      this.getJourneys()
    }
  },
  methods: {
    getJourneys () {
      if (!this.username) return
      existingJourneys({ username: this.username }).then(response => {
        this.options = response.data.map(item => {
          return {
            label: item.name,
            sublabel: item.info,
            stamp: item.date.date,
            value: item.reference
          }
        })
      })
    },
    reset () {
      this.reference = null
      this.change()
    },
    change () {
      this.$emit('change', {
        name: this.name,
        reference: this.reference
      })
    }
  }
}
</script>

<style lang="stylus" scoped>
h5
  text-align center
  margin-top 20px
  font-size 32px
  font-weight 300
  font-style italic

.unavailable-journey-text
  justify-content space-around
  text-align center
  color faded
</style>
