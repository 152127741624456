<template>
  <form class="row q-col-gutter-sm">
    <div class="col-sm-12">
      <m-group-title label="Booking information" />
      <q-card class="bg-white">
        <q-card-section>
          <div class="row q-col-gutter-sm">
            <div class="col-sm-4">
              <place-select
                v-model="booking.start_location"
                label="Pickup location"
              />
            </div>
            <div class="col-sm-4">
              <place-select
                v-model="booking.end_location"
                label="Dropoff location"
              />
            </div>
            <div class="col-sm-4">
              <q-input
                v-model="booking.number_of_passengers"
                type="number"
                label="Number of passengers"
              />
            </div>
            <div class="col-sm-12">
              <datetime-picker
                v-model="booking.pickup_time"
                label="Pickup at"
              />
            </div>
          </div>
        </q-card-section>
      </q-card>
    </div>
  </form>
</template>

<script>
import placeSelect from './shared/place-select'
import datetimePicker from './shared/datetime-picker'
import { MGroupTitle } from 'components/'

export default {
  name: 'BookingDrt',
  components: {
    placeSelect,
    datetimePicker,
    MGroupTitle
  },
  props: {
    value: Object
  },
  data () {
    return {
      booking: {
        content_type: {
          label: null,
          value: 'drt'
        },
        start_location: {
          label: null,
          value: null
        },
        end_location: {
          label: null,
          value: null
        },
        number_of_passengers: {
          label: null,
          value: null
        },
        pickup_time: {
          label: null,
          value: null
        }
      }
    }
  },
  watch: {
    booking: {
      deep: true,
      handler (val) {
        this.$emit('input', val)
      }
    }
  },
  created () {
    if (Object.keys(this.value).length) {
      this.booking = {
        ...this.booking,
        ...this.value
      }
      this.booking.content_type = { label: null, value: 'drt' }
    }
  }
}
</script>

<style lang="stylus" scoped>

</style>
