<template>
  <div class="grid">
    <q-item>
      <q-item-label>
        <q-item-label label>
          Start location
        </q-item-label>
        <q-item-label caption>
          {{ booking.start_location ? booking.start_location.value : null }}
        </q-item-label>
      </q-item-label>
    </q-item>
    <q-item>
      <q-item-label>
        <q-item-label label>
          Vehicle
        </q-item-label>
        <q-item-label caption>
          {{ booking.vehicle ? booking.vehicle.value : null }}
        </q-item-label>
      </q-item-label>
    </q-item>
    <q-item>
      <q-item-label>
        <q-item-label label>
          Depart at
        </q-item-label>
        <q-item-label caption>
          {{ booking.depart_at ? booking.depart_at.value : null | date }}
        </q-item-label>
      </q-item-label>
    </q-item>
    <q-item>
      <q-item-label>
        <q-item-label label>
          Return at
        </q-item-label>
        <q-item-label caption>
          {{ booking.arrive_at ? booking.arrive_at.value : null | date }}
        </q-item-label>
      </q-item-label>
    </q-item>
    <q-item>
      <q-item-label>
        <q-item-label label>
          Supplier name
        </q-item-label>
        <q-item-label caption>
          {{ booking.supplier_name ? booking.supplier_name.value : null }}
        </q-item-label>
      </q-item-label>
    </q-item><q-item>
      <q-item-label>
        <q-item-label label>
          Supplier booking reference
        </q-item-label>
        <q-item-label caption>
          {{ booking.supplier_booking_reference ? booking.supplier_booking_reference.value : null }}
        </q-item-label>
      </q-item-label>
    </q-item>
  </div>
</template>

<script>
import date from 'utils/date-time'
export default {
  filters: {
    date (data) {
      return data ? date.toShortDateTime(data) : null
    }
  },
  props: ['booking']

}
</script>

<style lang="stylus" scoped>
.grid
  display grid
  grid-template-columns 50% 50%
  font-weight 400
  align-items baseline
</style>
