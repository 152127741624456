<template>
  <q-card :class="type === 'purchase' ? 'no-shadow purchase' : ''" class="invoice bg-white">
    <q-card-section class="title">
      {{ `Line ${index + 1}` }}
      <div align="right">
        <q-btn
          dense
          flat
          label="delete line"
          @click="$emit('remove')"
        />
      </div>
    </q-card-section>
    <q-card-section>
      <div class="row q-col-gutter-sm">
        <div class="col-1">
          <q-input
            v-model="item.quantity"
            type="number"
            stack-label
            label="Quantity"
          />
        </div>

        <div class="col-4">
          <q-input
            v-model="item.description"
            stack-label
            label="Description"
          />
        </div>

        <div class="col-xs-12 col-sm-2">
          <money-field
            v-model="item.unit_price"
            label="Unit Price"
          />
        </div>

        <div class="col-2">
          <q-select
            v-model="item.tax_rate"
            :options="taxRates"
            stack-label
            label="Tax Rate"
            map-options
            emit-value
          />
        </div>

        <div class="col-2">
          <q-checkbox v-model="item.unit_price_includes_tax" class="checkbox-vat" label="Unit price includes tax" />
        </div>
      </div>
    </q-card-section>
  </q-card>
</template>

<script>
import _ from 'lodash'
import { select, values as taxValues } from 'store/vat-rates'
import Money from 'js-money'
import moneyField from 'components/MFields/money.vue'
export default {
  components: { moneyField },
  props: {
    type: String,
    value: {
      type: Object,
      default: () => {}
    },
    index: Number
  },
  data () {
    return {
      item: _.cloneDeep(this.value),
      taxRates: select
    }
  },
  computed: {
    taxRate () {
      return taxValues[this.item.tax_rate]
    },
    unitPrice () {
      const lowestCommon = this.item.unit_price !== '' && this.item.unit_price !== null
        ? parseInt(this.item.unit_price)
        : 0
      const amount = Money.fromInteger(lowestCommon, Money[this.item.currency], Math.round)

      if (this.item.unit_price_includes_tax) {
        const vat = this.vatAmountFromGross(amount)
        return amount.subtract(vat)
      } else {
        return amount
      }
    },
    net () {
      return this.unitPrice.multiply(this.item.quantity, Math.round)
    },
    tax () {
      return (this.unitPrice.multiply(this.item.quantity)).multiply(this.taxRate, Math.round)
    },
    gross () {
      return this.net.add(this.tax)
    }
  },
  watch: {
    item: {
      deep: true,
      handler (val) {
        this.$emit('input', {
          ...this.item,
          net: this.net,
          tax: this.tax,
          gross: this.gross
        })
      }
    }
  },
  methods: {
    vatAmountFromGross (nGrossAmount) {
      return nGrossAmount.divide((1 + (1 / this.taxRate)))
    }
  }
}
</script>

<style lang="stylus">
.checkbox-vat
  margin-top 10px
.purchase
  border 1px solid #979797
</style>
