<template>
  <div class="card-container">
    <q-card class="no-shadow item-card" @click="openRequest">
      <q-item>
        <q-item-section>
          <q-chip class="bg-transparent">
            <m-icons-avatar
              :type="item.content_type !== 'other' ? item.content_type : 'concierge'"
              :title="item.content_type"
              :inverted="true"
            />
            &nbsp;{{ item.$id }}
          </q-chip>
        </q-item-section>
        <q-item-section>
          <q-item-label>{{ item.created_at | date }}</q-item-label>
        </q-item-section>
        <q-item-section>
          <q-item-label class="ellipsis-2-lines">
            {{ item.status }}
          </q-item-label>
        </q-item-section>
        <q-item-section>
          <q-item-label>{{ item.type }}</q-item-label>
        </q-item-section>
        <q-item-section>
          <q-item-label>{{ item.organisation ? item.organisation.name : 'Individual' }}</q-item-label>
        </q-item-section>
        <q-item-section>
          <q-item-label>{{ item.requester ? item.requester.display_name : 'Unknown' }}</q-item-label>
        </q-item-section>
        <q-item-section>
          <q-item-label>{{ item.passenger_name ? item.passenger_name : item.user ? item.user.display_name : 'Unknown' }}</q-item-label>
        </q-item-section>
        <q-item-section>
          <q-item-label @click.prevent.stop>
            {{ item.assignee ? item.assignee.display_name : 'Unassigned' }}
            <q-btn
              round
              flat
              dense
              size="sm"
              icon="person_add"
              @click.prevent.stop="$emit('assign', item.$id)"
            />
          </q-item-label>
        </q-item-section>
      </q-item>
    </q-card>
  </div>
</template>
<script>
import date from 'utils/date-time'
import { MIconsAvatar } from 'components/'

export default {
  components: { MIconsAvatar },
  filters: {
    date (data) {
      return data ? date.toShortDate(data) : null
    }
  },
  props: ['item', 'index'],
  methods: {
    openRequest () {
      this.$router.push({ path: `/request/${this.item.reference}` })
    }
  }
}
</script>
<style lang="stylus" scoped>
.card-container
  height 62px
  overflow hidden
.q-card:not(.loading)
  height 52px
  outline 0.5px solid #e9e9e9
  display inline-block
  width calc(100% - 15px)
.col
  vertical-align bottom

.item-card
  font-size 15px
  line-height 18px
.hover .item-card
  background #e1e1e1
  cursor pointer
</style>
