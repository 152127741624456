<template>
  <div class="grid">
    <q-item>
      <q-item-label>
        <q-item-label label>
          Location
        </q-item-label>
        <q-item-label caption>
          {{ booking.location ? booking.location.label : null }}
        </q-item-label>
      </q-item-label>
    </q-item>
    <q-item>
      <q-item-label>
        <q-item-label label>
          Arrival time
        </q-item-label>
        <q-item-label caption>
          {{ booking.arrive_at ? booking.arrive_at.value: null | date }}
        </q-item-label>
      </q-item-label>
    </q-item>
    <q-item>
      <q-item-label>
        <q-item-label label>
          Departure time
        </q-item-label>
        <q-item-label caption>
          {{ booking.depart_at ? booking.depart_at.value : null | date }}
        </q-item-label>
      </q-item-label>
    </q-item>
  </div>
</template>

<script>
import date from 'utils/date-time'
export default {
  filters: {
    date (data) {
      return data ? date.toShortDateTime(data) : null
    }
  },
  props: ['booking']

}
</script>

<style lang="stylus" scoped>
.grid
  display grid
  grid-template-columns 50% 50%
  font-weight 400
  align-items baseline
</style>
