<template>
  <div class="grid">
    <q-item>
      <q-item-label>
        <q-item-label label>
          Pick up location
        </q-item-label>
        <q-item-label caption>
          {{ booking.pickup_location ? booking.pickup_location.value : null }}
        </q-item-label>
      </q-item-label>
    </q-item>
    <q-item>
      <q-item-label>
        <q-item-label label>
          Pick up time
        </q-item-label>
        <q-item-label caption>
          {{ booking.pickup_datetime ? booking.pickup_datetime.value: null | date }}
        </q-item-label>
      </q-item-label>
    </q-item>
    <q-item>
      <q-item-label>
        <q-item-label label>
          Drop off location
        </q-item-label>
        <q-item-label caption>
          {{ booking.dropoff_location ? booking.dropoff_location.value : null }}
        </q-item-label>
      </q-item-label>
    </q-item>
  </div>
</template>

<script>
import date from 'utils/date-time'
export default {
  filters: {
    date (data) {
      return data ? date.toShortDateTime(data) : null
    }
  },
  props: ['booking']

}
</script>

<style lang="stylus" scoped>
.grid
  display grid
  grid-template-columns 50% 50%
  font-weight 400
  align-items baseline
</style>
