<template>
  <div class="payments">
    <div class="payments-title">
      <m-group-title
        class="title"
        label="Payments"
        columns="null"
      />
      <q-btn
        flat
        label="Add Payment"
        @click="create = true"
      />
    </div>

    <q-card v-if="payments.length === 0" class="bg-white">
      <q-card-section>
        <m-empty-state
          :style="{padding: '30px', margin: 'auto'}"
          icon="receipt"
        >
          Add payments here
        </m-empty-state>
      </q-card-section>
    </q-card>

    <q-table
      v-else
      :data="payments"
      :columns="columns"
      :style="{
        boxShadow: !card && 'none'
      }"
      class="bg-white table"
      row-key="reference"
      hide-bottom
    >
      <q-tr slot="bottom-row">
        <q-td>
          <strong>Payments Total</strong>
        </q-td>
        <q-td />
        <q-td>
          £{{ total }}
        </q-td>
      </q-tr>
    </q-table>

    <q-dialog
      v-model="create"
    >
      <q-card>
        <q-card-section>
          <div class="text-h6">
            Record a payment
          </div>
        </q-card-section>
        <q-card-section>
          <form>
            <div class="row q-col-gutter-sm">
              <div class="col-xs-6">
                <q-select
                  v-model="draftPayment.currency"
                  :options="[{
                    label: 'GBP',
                    value: 'GBP'
                  }]"
                  emit-value
                  map-options
                  stack-label
                  label="Currency"
                />
              </div>
              <div class="col-xs-6">
                <q-input
                  v-model="draftPayment.amount"
                  type="number"
                  stack-label
                  label="Amount"
                />
              </div>
              <div class="col-xs-6">
                <q-select
                  v-model="draftPayment.type"
                  :options="[{
                    label: 'Card',
                    value: 'card'
                  }]"
                  emit-value
                  map-options
                  stack-label
                  label="Payment Method"
                />
              </div>
              <div class="col-xs-6">
                <q-input
                  v-model="draftPayment.merchant"
                  stack-label
                  label="Merchant"
                />
              </div>
              <div class="col-xs-12">
                <q-input
                  v-model="draftPayment.merchant_reference"
                  stack-label
                  label="Reference"
                />
              </div>
            </div>
          </form>
        </q-card-section>
        <q-card-actions>
          <div v-if="error" class="invalid">
            All fields required
          </div>
        </q-card-actions>
        <q-card-actions align="right">
          <q-btn
            color="primary"
            label="Create"
            @click="addPayment"
          />
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import _ from 'lodash'
import Money from 'js-money'
import { required } from 'vuelidate/lib/validators'
import { MGroupTitle, MEmptyState } from 'components/'

const draftModel = {
  amount: null,
  currency: 'GBP',
  type: 'card',
  merchant: null,
  merchant_reference: null
}

export default {
  components: { MGroupTitle, MEmptyState },
  props: {
    requestReference: {
      type: String,
      required: true
    },
    card: {
      type: Boolean,
      default: false
    },
    payments: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      create: false,
      error: false,
      draftPayment: _.cloneDeep(draftModel),
      columns: [
        {
          required: true,
          label: 'Merchant',
          align: 'left',
          field: p => p.merchant
        },
        {
          required: true,
          label: 'Method',
          align: 'left',
          field: p => p.type
        },
        {
          required: true,
          label: 'Amount',
          align: 'left',
          field: p => p.transaction.text
        }
      ]
    }
  },
  computed: {
    total () {
      return (_.sumBy(this.payments, p => p.transaction.amount) / 100).toFixed(2)
    }
  },
  methods: {
    addPayment () {
      this.$v.draftPayment.$touch()

      if (this.$v.$invalid) {
        this.error = true
      } else {
        this.error = false
        this.create = false
        const lowestDenomination = Money.fromDecimal(this.draftPayment.amount, this.draftPayment.currency).amount
        this.draftPayment.amount = lowestDenomination

        this.$store.dispatch('requests/addPayment', {
          request: this.requestReference,
          payment: this.draftPayment
        })
          .then(() => {
            this.draftPayment = _.cloneDeep(draftModel)
          })
      }
    }
  },
  validations: {
    draftPayment: {
      amount: { required },
      currency: { required },
      type: { required },
      merchant: { required },
      merchant_reference: { required }
    }
  }
}
</script>

<style lang="stylus" scoped>
.payments-title
  display flex
  justify-content space-between

.payments-title .title
  margin-top 10px

form
  max-width 500px

.table
  margin-top 8px
.invalid
  margin-right 10px
  line-height 2.3
  color: red
</style>
