<template>
  <q-page class="layout-padding">
    <booking-form :filled-requester="requester" />
  </q-page>
</template>

<script>
import bookingForm from '../forms/booking-form'

export default {
  components: {
    bookingForm
  },
  props: {
    requester: Object
  }
}
</script>

<style>

</style>
