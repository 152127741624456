<template>
  <m-select-query
    v-model="booking"
    v-bind="$attrs"
    label="Booking"
    :query="getBookings"
    clearable
  />
</template>

<script>
import { index } from 'api/bookings'
import { MSelectQuery } from 'components/'

export default {
  components: { MSelectQuery },
  props: {
    value: Object,
    user: Object
  },
  computed: {
    booking: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  },
  methods: {
    getBookings (string) {
      return index({
        user: this.user.value,
        reference: string
      })
        .then(bookings => {
          return bookings.data.map(b => ({
            label: b.reference,
            value: b.reference,
            description: b.name,
            content_type: b.type
          }))
        })
    }
  }
}
</script>

<style>

</style>
