<template>
  <q-btn-dropdown
    :label="label"
    split
    size="lg"
    outline
    style="display: block;"
    @click="() => $emit('primaryAction')"
  >
    <q-list link>
      <slot />
      <q-item clickable @click.native="$emit('close')">
        <q-item-section class="text-red">
          Close Request
        </q-item-section>
      </q-item>
    </q-list>
  </q-btn-dropdown>
</template>

<script>
export default {
  props: ['label']
}
</script>

<style>

</style>
