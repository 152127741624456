<template>
  <form class="row">
    <div class="col-sm-12">
      <m-group-title label="Booking information" />
      <q-card class="bg-white">
        <q-card-section>
          <place-select
            v-model="booking.location"
            label="Location"
          />

          <datetime-picker
            v-model="booking.arrive_at"
            label="Arrive at"
          />

          <datetime-picker
            v-model="booking.depart_at"
            label="Depart at"
          />
        </q-card-section>
      </q-card>
    </div>
  </form>
</template>

<script>
import datetimePicker from './shared/datetime-picker'
import placeSelect from './shared/place-select'
import { MGroupTitle } from 'components/'

export default {
  name: 'BookingFormParking',
  components: {
    datetimePicker,
    placeSelect,
    MGroupTitle
  },
  props: {
    value: Object
  },
  data () {
    return {
      booking: {
        content_type: {
          value: 'parking',
          label: 'Parking'
        },
        location: {
          value: null,
          label: null
        },
        arrive_at: {
          value: null,
          label: null
        },
        depart_at: {
          value: null,
          label: null
        }
      }
    }
  },
  watch: {
    booking: {
      deep: true,
      handler (val) {
        this.$emit('input', val)
      }
    }
  },
  created () {
    if (Object.keys(this.value).length) {
      Object.keys(this.value).forEach(key => {
        this.booking[key] = this.value[key]
      })
      this.booking.content_type = { label: null, value: 'parking' }
    }
  }
}
</script>

<style lang="stylus" scoped>

</style>
