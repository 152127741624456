export default function shapeUser (u) {
  let org = null
  if (u.organisation) {
    if (u.organisation.data) {
      org = u.organisation.data
    } else {
      org = u.organisation
    }
  }

  return {
    label: u.display_name,
    value: u.username,
    description: `Organisation: ${org ? org.name : 'Individual'}`,
    organisation: org ? org.slug : 'Individual',
    permissions: u.permissions,
    avatar: u.picture
  }
}
