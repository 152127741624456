<template>
  <div class="grid">
    <q-item>
      <q-item-label>
        <q-item-label label>
          Start Location
        </q-item-label>
        <q-item-label caption>
          {{ (booking.start_location_address || booking.start_location_branch_code).label }}
        </q-item-label>
      </q-item-label>
    </q-item>
    <q-item>
      <q-item-label>
        <q-item-label label>
          End Location
        </q-item-label>
        <q-item-label caption>
          {{ (booking.end_location_address || booking.end_location_branch_code).label }}
        </q-item-label>
      </q-item-label>
    </q-item>
    <q-item>
      <q-item-label>
        <q-item-label label>
          Depart at
        </q-item-label>
        <q-item-label caption>
          {{ booking.depart_at.value | date }}
        </q-item-label>
      </q-item-label>
    </q-item>
    <q-item>
      <q-item-label>
        <q-item-label label>
          Return at
        </q-item-label>
        <q-item-label caption>
          {{ booking.arrive_at.value | date }}
        </q-item-label>
      </q-item-label>
    </q-item>
    <q-item>
      <q-item-label>
        <q-item-label label>
          Rental Company Name
        </q-item-label>
        <q-item-label caption>
          {{ booking.rental_company_name.value }}
        </q-item-label>
      </q-item-label>
    </q-item>
    <q-item>
      <q-item-label>
        <q-item-label label>
          Rental Company Reference
        </q-item-label>
        <q-item-label caption>
          {{ booking.rental_company_reference.value }}
        </q-item-label>
      </q-item-label>
    </q-item>
    <q-item>
      <q-item-label>
        <q-item-label label>
          Vehicle Group
        </q-item-label>
        <q-item-label caption>
          {{ booking.vehicle_group.value }}
        </q-item-label>
      </q-item-label>
    </q-item>
  </div>
</template>

<script>
import date from 'utils/date-time'
export default {
  filters: {
    date (data) {
      return date.toShortDateTime(data)
    }
  },
  props: ['booking']

}
</script>

<style lang="stylus" scoped>
.grid
  display grid
  grid-template-columns 50% 50%
  font-weight 400
  align-items baseline
</style>
