<template>
  <q-page>
    <div class="layout-padding booking-form-container">
      <component
        :is="form"
        v-if="$route.params.booking ? loaded : true"
        v-model="booking"
      />

      <div class="col-sm-12">
        <note
          v-if="$route.params.booking ? loaded : true"
          v-model="note"
        />
      </div>

      <div class="col-sm-12">
        <invoice
          v-model="invoiceItems"
          type="sales"
        />
      </div>
      <div class="col-sm-12">
        <purchase-invoices
          :invoices="request.invoices || []"
          class="purchase-invoices"
        />
      </div>

      <submit-amend
        v-if="$route.params.booking"
        :booking="apiBooking"
        :booking-reference="$route.params.booking"
        :invoice-items="invoiceItems"
        :note="note"
      />
      <submit-booking
        v-else
        :booking="booking"
        :invoice-items="invoiceItems"
        :note="note"
      />
    </div>
  </q-page>
</template>

<script>
import _ from 'lodash'
import store from 'store'
import { mapGetters } from 'vuex'
import http from 'api/http'
import chauffeur from '../forms/chauffeur'
import drt from '../forms/drt'
import flight from '../forms/flight'
import hotel from '../forms/hotel'
import lounge from '../forms/lounge'
import parking from '../forms/parking'
import rail from '../forms/rail'
import rental from '../forms/rental'
import ridehailing from '../forms/ridehailing'
import onwardtravel from '../forms/onwardtravel'
import eurostar from '../forms/eurostar'
import locallink from '../forms/locallink'
import carclub from '../forms/carclub'

import invoice from '../../invoice/invoice'
import purchaseInvoices from '../../view/invoices'
import note from '../forms/shared/note'
import submitBooking from '../forms/shared/submit-booking'
import submitAmend from '../forms/shared/submit-amend'

const lookupKeys = ['arrive_iata', 'depart_iata', 'start_location', 'end_location', 'airport_iata',
  'location', 'depart_atoc', 'arrive_atoc', 'arrive_code', 'depart_code', 'start_location_place_id', 'start_location_branch_code',
  'end_location_place_id', 'end_location_branch_code']

function formatBooking (booking, lookups) {
  return _.cloneDeepWith(booking, (item, key) => {
    if (typeof item === 'string') {
      return {
        label: lookupKeys.includes(key) && lookups && lookups[item] ? lookups[item] : item,
        value: item
      }
    }
  })
}

function deformatBooking (booking) {
  return _.cloneDeepWith(booking, (item, key) => {
    if (item && typeof item === 'object' && item.hasOwnProperty('value') && item.hasOwnProperty('label')) {
      return item.value
    }
  })
}

export default {
  components: {
    chauffeur,
    drt,
    flight,
    hotel,
    lounge,
    parking,
    rail,
    rental,
    ridehailing,
    onwardtravel,
    eurostar,
    locallink,
    carclub,
    invoice,
    purchaseInvoices,
    note,
    submitBooking,
    submitAmend
  },
  data () {
    return {
      loaded: false,
      booking: {},
      invoiceItems: [],
      note: ''
    }
  },
  beforeRouteEnter (to, from, next) {
    const requests = []

    if (to.params.booking) {
      requests.push(http.get(`/bookings/${to.params.booking}?include=notes`))
    }

    requests.push(
      store.dispatch('requests/loadResource', {
        id: to.params.reference
      })
    )

    Promise.all(requests)
      .then(([res]) => {
        if (to.params.booking) {
          next(vm => {
            const booking = res.data
            vm.booking = formatBooking(booking.attributes, booking.lookups)
            vm.note = booking.notes.data[0] ? booking.notes.data[0].body : null
            vm.loaded = true
          })
        } else {
          next(vm => {
            vm.booking.content_type = { label: null, value: vm.request.content_type }
          })
        }
      })
  },
  computed: {
    ...mapGetters({
      getRequest: 'requests/getResource'
    }),
    request () {
      return this.getRequest(this.$route.params.reference)
    },
    form () {
      return this.request.content_type
    },
    apiBooking () {
      return deformatBooking(this.booking)
    }
  },
  created () {
    this.booking.summary = this.request.summary
  },
  methods: {
    setInvoice (data) {
      this.invoiceItems = data
    },
    setNote (data) {
      this.note = data
    }
  }
}
</script>

<style lang="stylus" scoped>
.booking-form-container
  height calc(100vh - 64px)
  overflow: auto
h4
  margin-bottom 20px
.purchase-invoices
  margin-top 15px
</style>
