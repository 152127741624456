<template>
  <div class="grid">
    <q-item>
      <q-item-label>
        <q-item-label label>
          Airport
        </q-item-label>
        <q-item-label caption>
          {{ booking.airport_iata ? booking.airport_iata.label : null }}
        </q-item-label>
      </q-item-label>
    </q-item>
    <q-item>
      <q-item-label>
        <q-item-label label>
          Arrival time
        </q-item-label>
        <q-item-label caption>
          {{ booking.arrive_at.value | date }}
        </q-item-label>
      </q-item-label>
    </q-item>
  </div>
</template>

<script>
import date from 'utils/date-time'
export default {
  filters: {
    date (data) {
      return date.toShortDateTime(data)
    }
  },
  props: ['booking']

}
</script>

<style lang="stylus" scoped>
.grid
  display grid
  grid-template-columns 50% 50%
  font-weight 400
  align-items baseline
</style>
