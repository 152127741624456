<template>
  <m-select-query
    v-model="place"
    :label="label"
    :placeholder="placeholder"
    icon="place"
    :query="searchLocation"
    clearable
  />
</template>

<script>
import { query } from 'api/places'
import { MSelectQuery } from 'components/'

export default {
  components: { MSelectQuery },
  props: {
    value: {
      type: Object,
      default: () => ({
        label: null,
        value: null
      })
    },
    label: String,
    placeholder: {
      type: String,
      default: 'Enter a location...'
    }
  },
  computed: {
    place: {
      get () {
        return this.value.value ? this.value : null
      },
      set (val) {
        if (val) this.$emit('input', val)
      }
    }
  },
  methods: {
    searchLocation (address) {
      return query(address).then(response => {
        return response.data.map(location => {
          return {
            label: location.description,
            value: location.place_id,
            icon: 'place'
          }
        })
      })
    }
  }
}
</script>

<style>

</style>
