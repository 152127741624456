<template>
  <div class="grid">
    <q-item>
      <q-item-section>
        <q-item-label label>
          Hotel Confirmation Number
        </q-item-label>
        <q-item-label caption>
          {{ booking.hotel_confirmation_number.value }}
        </q-item-label>
      </q-item-section>
      <q-item-section>
        <q-item-label label>
          Hotel Name
        </q-item-label>
        <q-item-label caption>
          {{ booking.name.value }}
        </q-item-label>
      </q-item-section>
    </q-item>
    <q-item>
      <q-item-section>
        <q-item-label label>
          Room Type
        </q-item-label>
        <q-item-label caption>
          {{ booking.room_type.value }}
        </q-item-label>
      </q-item-section>
    </q-item>
    <q-item>
      <q-item-section>
        <q-item-label label>
          Check in
        </q-item-label>
        <q-item-label caption>
          {{ (booking.check_in ? booking.check_in.value : null) | date }}
        </q-item-label>
      </q-item-section>
    </q-item>
    <q-item>
      <q-item-section>
        <q-item-label label>
          Check out
        </q-item-label>
        <q-item-label caption>
          {{ (booking.check_out ? booking.check_out.value : null) | date }}
        </q-item-label>
      </q-item-section>
    </q-item>
  </div>
</template>

<script>
import date from 'utils/date-time'
export default {
  filters: {
    date (data) {
      return data ? date.toShortDateTime(data) : null
    }
  },
  props: ['booking']

}
</script>

<style lang="stylus" scoped>
.grid
  display grid
  grid-template-columns 50% 50%
  font-weight 400
  align-items baseline
</style>
