var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"payments"},[_c('div',{staticClass:"payments-title"},[_c('m-group-title',{staticClass:"title",attrs:{"label":"Payments","columns":"null"}}),_c('q-btn',{attrs:{"flat":"","label":"Add Payment"},on:{"click":function($event){_vm.create = true}}})],1),(_vm.payments.length === 0)?_c('q-card',{staticClass:"bg-white"},[_c('q-card-section',[_c('m-empty-state',{style:({padding: '30px', margin: 'auto'}),attrs:{"icon":"receipt"}},[_vm._v(" Add payments here ")])],1)],1):_c('q-table',{staticClass:"bg-white table",style:({
      boxShadow: !_vm.card && 'none'
    }),attrs:{"data":_vm.payments,"columns":_vm.columns,"row-key":"reference","hide-bottom":""}},[_c('q-tr',{attrs:{"slot":"bottom-row"},slot:"bottom-row"},[_c('q-td',[_c('strong',[_vm._v("Payments Total")])]),_c('q-td'),_c('q-td',[_vm._v(" £"+_vm._s(_vm.total)+" ")])],1)],1),_c('q-dialog',{model:{value:(_vm.create),callback:function ($$v) {_vm.create=$$v},expression:"create"}},[_c('q-card',[_c('q-card-section',[_c('div',{staticClass:"text-h6"},[_vm._v(" Record a payment ")])]),_c('q-card-section',[_c('form',[_c('div',{staticClass:"row q-col-gutter-sm"},[_c('div',{staticClass:"col-xs-6"},[_c('q-select',{attrs:{"options":[{
                  label: 'GBP',
                  value: 'GBP'
                }],"emit-value":"","map-options":"","stack-label":"","label":"Currency"},model:{value:(_vm.draftPayment.currency),callback:function ($$v) {_vm.$set(_vm.draftPayment, "currency", $$v)},expression:"draftPayment.currency"}})],1),_c('div',{staticClass:"col-xs-6"},[_c('q-input',{attrs:{"type":"number","stack-label":"","label":"Amount"},model:{value:(_vm.draftPayment.amount),callback:function ($$v) {_vm.$set(_vm.draftPayment, "amount", $$v)},expression:"draftPayment.amount"}})],1),_c('div',{staticClass:"col-xs-6"},[_c('q-select',{attrs:{"options":[{
                  label: 'Card',
                  value: 'card'
                }],"emit-value":"","map-options":"","stack-label":"","label":"Payment Method"},model:{value:(_vm.draftPayment.type),callback:function ($$v) {_vm.$set(_vm.draftPayment, "type", $$v)},expression:"draftPayment.type"}})],1),_c('div',{staticClass:"col-xs-6"},[_c('q-input',{attrs:{"stack-label":"","label":"Merchant"},model:{value:(_vm.draftPayment.merchant),callback:function ($$v) {_vm.$set(_vm.draftPayment, "merchant", $$v)},expression:"draftPayment.merchant"}})],1),_c('div',{staticClass:"col-xs-12"},[_c('q-input',{attrs:{"stack-label":"","label":"Reference"},model:{value:(_vm.draftPayment.merchant_reference),callback:function ($$v) {_vm.$set(_vm.draftPayment, "merchant_reference", $$v)},expression:"draftPayment.merchant_reference"}})],1)])])]),_c('q-card-actions',[(_vm.error)?_c('div',{staticClass:"invalid"},[_vm._v(" All fields required ")]):_vm._e()]),_c('q-card-actions',{attrs:{"align":"right"}},[_c('q-btn',{attrs:{"color":"primary","label":"Create"},on:{"click":_vm.addPayment}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }