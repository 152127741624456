<template>
  <q-drawer
    v-model="open"
    :width="557"
    class="booking-drawer"
    side="right"
  >
    <q-tabs
      v-model="currentTab"
      no-pane-border
      class="bg white text-dark"
    >
      <q-tab
        default
        name="request"
        label="Request"
      />
      <q-tab
        name="notes"
        label="Notes"
      />
      <q-tab
        name="payments"
        label="Payments"
      />
    </q-tabs>
    <q-tab-panels v-model="currentTab">
      <q-tab-panel name="request">
        <div
          v-if="request"
          class="row q-col-gutter-sm"
        >
          <div class="col-sm-12">
            <div class="content-type">
              <div class="row">
                <div class="col-sm-1 icon">
                  <m-icons-type
                    :inverted="true"
                    :type="request.content_type"
                    size="inherit"
                  />
                </div>
                <div class="col-sm-7">
                  <h5>{{ $tc(`content_type.${request.content_type}`) }} {{ request.type }} <small class="text-faded">{{ request.reference }}</small></h5>
                </div>
                <div class="col-sm-4 text-white">
                  <q-chip
                    v-if="request.required_by"
                    icon="alarm"
                    class="required-by text-white"
                    color="primary"
                  >
                    {{ request.required_by.date | date }}
                  </q-chip>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <q-item class="person">
              <q-item-section avatar>
                <q-avatar>
                  <img :src="request.requester.picture">
                </q-avatar>
              </q-item-section>
              <q-item-section>
                <q-item-label label>
                  {{ request.requester.display_name }}
                </q-item-label>
                <q-item-label caption>
                  Requester
                </q-item-label>
              </q-item-section>
            </q-item>
          </div>
          <div class="col-sm-6">
            <q-item class="person">
              <q-item-section avatar>
                <q-avatar>
                  <img :src="request.user.picture">
                </q-avatar>
              </q-item-section>
              <q-item-label>
                <q-item-label label>
                  {{ request.user.display_name }}
                </q-item-label>
                <q-item-label caption>
                  Traveller
                </q-item-label>
              </q-item-label>
            </q-item>
          </div>
          <div class="col-sm-12">
            <m-editor-viewer :content="request.message" />
          </div>
          <div class="col-sm-12">
            <q-item
              v-if="request.assignee"
              class="person"
            >
              <q-item-section avatar>
                <q-avatar>
                  <img :src="request.assignee.picture">
                </q-avatar>
              </q-item-section>
              <q-item-label>
                <q-item-label label>
                  {{ request.assignee.display_name }}
                </q-item-label>
                <q-item-label caption>
                  Assignee
                </q-item-label>
              </q-item-label>
            </q-item>
          </div>
        </div>
      </q-tab-panel>

      <q-tab-panel name="notes">
        <div class="col-sm-12">
          <resource-notes
            :notes="notes"
            :fab="false"
          />
        </div>
      </q-tab-panel>

      <q-tab-panel name="payments">
        <div class="col-sm-12">
          <payments
            :payments="request.payments"
            :request-reference="request.reference"
          />
        </div>
      </q-tab-panel>
    </q-tab-panels>
  </q-drawer>
</template>

<script>
import date from 'utils/date-time'
import { notes } from 'api/requests'
import { mapGetters } from 'vuex'
import payments from '../../view/payments'
import { MIconsType, MEditorViewer } from 'components/'

export default {
  components: {
    payments,
    MIconsType,
    MEditorViewer
  },
  filters: {
    date (data) {
      return date.toShortDateTime(data)
    }
  },
  data () {
    return {
      open: true,
      currentTab: 'request',
      notes: []
    }
  },
  computed: {
    ...mapGetters({
      getRequest: 'requests/getResource'
    }),
    request () {
      return this.getRequest(this.$route.params.reference)
    }
  },
  created () {
    notes(this.$route.params.reference)
      .then((notes) => {
        this.notes = notes.data
      })
  }
}
</script>

<style lang="stylus">
.content-type
  padding-top 10px
.booking-drawer .q-drawer
  width 30%
h5
  text-transform: capitalize
</style>
