<template>
  <div>
    <h6>Outbound Legs</h6>
    <div v-for="(leg, i) in booking.outbound_legs" :key="i" class="leg">
      <div class="grid">
        <q-item>
          <q-item-label>
            <q-item-label label>
              Departure Airport
            </q-item-label>
            <q-item-label caption>
              {{ leg.depart_iata.label }}
            </q-item-label>
          </q-item-label>
        </q-item>
        <q-item>
          <q-item-label>
            <q-item-label label>
              Arrival Airport
            </q-item-label>
            <q-item-label caption>
              {{ leg.arrive_iata.label }}
            </q-item-label>
          </q-item-label>
        </q-item>
        <q-item>
          <q-item-label>
            <q-item-label label>
              Departure Time
            </q-item-label>
            <q-item-label caption>
              {{ leg.depart_at.value | date }}
            </q-item-label>
          </q-item-label>
        </q-item>
        <q-item>
          <q-item-label>
            <q-item-label label>
              Arrival Time
            </q-item-label>
            <q-item-label caption>
              {{ leg.arrive_at.value | date }}
            </q-item-label>
          </q-item-label>
        </q-item>
        <q-item>
          <q-item-label>
            <q-item-label label>
              Flight Number
            </q-item-label>
            <q-item-label caption>
              {{ leg.flight_number.value }}
            </q-item-label>
          </q-item-label>
        </q-item>
        <q-item>
          <q-item-label>
            <q-item-label label>
              Supplier Reference
            </q-item-label>
            <q-item-label caption>
              {{ leg.supplier_reference.value }}
            </q-item-label>
          </q-item-label>
        </q-item>
      </div>
    </div>
    <h6>Return Legs</h6>
    <div v-for="(leg, i) in booking.return_legs" :key="'return' + i" class="leg">
      <div class="grid">
        <q-item>
          <q-item-label>
            <q-item-label label>
              Departure Airport
            </q-item-label>
            <q-item-label caption>
              {{ leg.depart_iata.label }}
            </q-item-label>
          </q-item-label>
        </q-item>
        <q-item>
          <q-item-label>
            <q-item-label label>
              Arrival Airport
            </q-item-label>
            <q-item-label caption>
              {{ leg.arrive_iata.label }}
            </q-item-label>
          </q-item-label>
        </q-item>
        <q-item>
          <q-item-label>
            <q-item-label label>
              Departure Time
            </q-item-label>
            <q-item-label caption>
              {{ leg.depart_at.value | date }}
            </q-item-label>
          </q-item-label>
        </q-item>
        <q-item>
          <q-item-label>
            <q-item-label label>
              Arrival Time
            </q-item-label>
            <q-item-label caption>
              {{ leg.arrive_at.value | date }}
            </q-item-label>
          </q-item-label>
        </q-item>
        <q-item>
          <q-item-label>
            <q-item-label label>
              Flight Number
            </q-item-label>
            <q-item-label caption>
              {{ leg.flight_number.value }}
            </q-item-label>
          </q-item-label>
        </q-item>
        <q-item>
          <q-item-label>
            <q-item-label label>
              Supplier Reference
            </q-item-label>
            <q-item-label caption>
              {{ leg.supplier_reference.value }}
            </q-item-label>
          </q-item-label>
        </q-item>
      </div>
    </div>
  </div>
</template>

<script>
import date from 'utils/date-time'
export default {
  filters: {
    date (data) {
      return data ? date.toShortDateTime(data) : null
    }
  },
  props: ['booking']

}
</script>

<style lang="stylus" scoped>
.grid
  display grid
  grid-template-columns repeat(auto-fill, 50%);
  font-weight 400
  align-items baseline

.leg
  background: whitesmoke
  border-radius 3px
  margin-bottom 12px
</style>
