<template>
  <q-dialog
    v-model="open"
    @ok="closeRequest"
  >
    <q-card>
      <q-card-section>
        <div class="text-h6">
          You are closing this request
        </div>
      </q-card-section>
      <q-card-section>
        <div class="text-body1">
          Please provide a reason for closing this request below
        </div>
      </q-card-section>
      <q-card-section>
        <div style="min-height: 225px;">
          <q-input
            v-model="cancelMessage"
            label="Reason for closing"
            type="text"
            rows="7"
            autogrow
          />
        </div>
      </q-card-section>
      <q-card-actions align="right">
        <q-btn
          :disable="!cancelMessage ? true : false"
          color="red"
          label="Close Request"
          @click="closeRequest"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
export default {
  props: ['value'],
  data () {
    return {
      cancelMessage: null
    }
  },
  computed: {
    open: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  },
  methods: {
    closeRequest () {
      this.$emit('close', this.cancelMessage)
    }
  }
}
</script>

<style>

</style>
