<template>
  <div>
    <q-btn
      round
      type="button"
      color="primary"
      size="lg"
      class="absolute"
      icon="done"
      style="right: 30px; bottom: 18px"
      @click="submit"
    />
    <m-error-dialog v-model="error" :error="errorResponse" />
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import processInvoice from '../../../invoice/process-invoice'
import http from 'api/http'
import { MErrorDialog } from 'components/'
export default {
  components: { MErrorDialog },
  props: {
    booking: Object,
    bookingReference: String,
    invoiceItems: Array,
    note: String,
    noteId: String
  },
  data () {
    return {
      localNote: this.note,
      error: false,
      errorResponse: null
    }
  },
  validations: {
    localNote: { required }
  },
  watch: {
    note (newVal) {
      this.localNote = newVal
    }
  },
  methods: {
    submit () {
      if (!this.$v.localNote.$invalid) {
        const invoices = [processInvoice(this.invoiceItems)]

        this.$store.dispatch('requests/cancelBooking', {
          reference: this.$route.params.reference,
          bookingReference: this.$route.params.booking,
          booking: { invoices }
        })
          .then(() => {
            return http.post(`/booking/${this.bookingReference}/notes`, {
              body: this.localNote,
              private: false
            })
          })
          .then(() => {
            this.$q.notify({
              message: `Booking cancelled`,
              color: 'positive',
              icon: 'mdi-check'
            })
            this.$router.push({ name: 'dashboard' })
          })
          .catch(e => {
            this.error = true
            this.errorResponse = e.data
          })
      } else {
        this.error = true
        this.errorResponse = { message: 'Additional Information is required.' }
      }
    }
  }
}
</script>

<style lang="stylus">
.modal-message
  white-space pre
</style>
