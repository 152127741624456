<template>
  <q-page class="layout-padding">
    <amend-form :filled-requester="requester" />
  </q-page>
</template>

<script>
import amendForm from '../forms/amend-form'
export default {
  components: {
    amendForm
  },
  props: {
    requester: Object
  }
}
</script>

<style>

</style>
