
<template>
  <div class="col-sm-6">
    <q-card class="bg-white">
      <q-card-section class="title">
        {{ `Leg ${legNumber}` }}
        <q-btn
          dense
          flat
          type="button"
          label="delete leg"
          class="float-right"
          @click="removeLeg"
        />
      </q-card-section>
      <q-separator />
      <q-card-section>
        <eurostar-select
          v-model="leg.depart_code"
          label="Depart From"
        />
        <datetime-picker
          v-model="leg.depart_at"
          label="Depart at"
        />
        <eurostar-select
          v-model="leg.arrive_code"
          label="Arrive in"
        />
        <datetime-picker
          v-model="leg.arrive_at"
          label="Arrive at"
        />
      </q-card-section>
    </q-card>
  </div>
</template>

<script>
import datetimePicker from './datetime-picker'
import eurostarSelect from './eurostar-select'
export default {
  components: {
    datetimePicker,
    eurostarSelect
  },
  props: {
    value: Object,
    legNumber: Number
  },
  computed: {
    leg: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  },
  methods: {
    removeLeg () {
      this.$emit('removeLeg')
    }
  }
}
</script>

<style>

</style>
