<template>
  <div class="grid">
    <q-item>
      <q-item-label>
        <q-item-label label>
          Pick up location
        </q-item-label>
        <q-item-label caption>
          {{ booking.start_location.label }}
        </q-item-label>
      </q-item-label>
    </q-item>
    <q-item>
      <q-item-label>
        <q-item-label label>
          Drop off location
        </q-item-label>
        <q-item-label caption>
          {{ booking.end_location.label }}
        </q-item-label>
      </q-item-label>
    </q-item>
    <q-item>
      <q-item-label>
        <q-item-label label>
          Depart date and time
        </q-item-label>
        <q-item-label caption>
          {{ (booking.depart_at ? booking.depart_at.value : null) | date }}
        </q-item-label>
      </q-item-label>
    </q-item>
    <q-item>
      <q-item-label>
        <q-item-label label>
          Driver instructions
        </q-item-label>
        <q-item-label caption>
          {{ booking.instructions.value }}
        </q-item-label>
      </q-item-label>
    </q-item>
    <q-item>
      <q-item-label>
        <q-item-label label>
          Phone number
        </q-item-label>
        <q-item-label caption>
          {{ booking.phone_number.value }}
        </q-item-label>
      </q-item-label>
    </q-item>
    <q-item>
      <q-item-label>
        <q-item-label label>
          Flight number
        </q-item-label>
        <q-item-label caption>
          {{ booking.flight_number.value }}
        </q-item-label>
      </q-item-label>
    </q-item>
  </div>
</template>

<script>
import date from 'utils/date-time'
export default {
  filters: {
    date (data) {
      return data ? date.toShortDateTime(data) : null
    }
  },
  props: ['booking']

}
</script>

<style lang="stylus" scoped>
.grid
  display grid
  grid-template-columns 50% 50%
  font-weight 400
  align-items baseline
</style>
