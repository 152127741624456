<template>
  <form class="row q-col-gutter-sm">
    <div class="col-sm-12">
      <m-group-title label="Booking information" />
      <q-card class="bg-white">
        <q-card-section>
          <div class="row q-col-gutter-sm">
            <div class="col-sm-6">
              <place-select
                v-model="booking.start_location"
                label="Pickup location"
              />
            </div>
            <div class="col-sm-6">
              <place-select
                v-model="booking.end_location"
                label="Dropoff location"
              />
            </div>
            <div class="col-sm-12">
              <datetime-picker
                v-model="booking.depart_at"
                label="Depart at"
              />
            </div>
            <div class="col-sm-6">
              <q-input
                v-model="booking.phone_number.value"
                type="text"
                stack-label
                label="Phone number"
              />
            </div>
            <div class="col-sm-6">
              <q-input
                v-model="booking.flight_number.value"
                type="text"
                stack-label
                label="Flight number"
                mask="SS####"
                unmasked-value
              />
            </div>
            <div class="col-sm-12">
              <q-input
                v-model="booking.instructions.value"
                type="text"
                stack-label
                label="Driver instructions"
              />
            </div>
          </div>
        </q-card-section>
      </q-card>
    </div>
  </form>
</template>

<script>
import placeSelect from './shared/place-select'
import datetimePicker from './shared/datetime-picker'
import { MGroupTitle } from 'components/'

export default {
  name: 'BookingFormRidehailing',
  components: {
    placeSelect,
    datetimePicker,
    MGroupTitle
  },
  props: {
    value: Object
  },
  data () {
    return {
      booking: {
        content_type: {
          label: null,
          value: 'ridehailing'
        },
        start_location: {
          label: null,
          value: null
        },
        end_location: {
          label: null,
          value: null
        },
        depart_at: {
          label: null,
          value: null
        },
        instructions: {
          label: null,
          value: null
        },
        phone_number: {
          label: null,
          value: null
        },
        flight_number: {
          label: null,
          value: null
        }
      }
    }
  },
  watch: {
    booking: {
      deep: true,
      handler (val) {
        this.$emit('input', val)
      }
    }
  },
  created () {
    if (Object.keys(this.value).length) {
      this.booking = {
        ...this.booking,
        ...this.value
      }
      this.booking.content_type = { label: null, value: 'ridehailing' }
    }
  }
}
</script>

<style lang="stylus" scoped>

</style>
