<template>
  <m-select-query
    v-model="iata"
    :label="label"
    icon="mdi-airplane-takeoff"
    :query="searchAirports"
  />
</template>

<script>
import { airports } from 'api/flights'
import { MSelectQuery } from 'components/'

export default {
  components: { MSelectQuery },
  props: {
    label: String,
    value: {
      type: Object
    },
    placeholder: {
      type: String,
      default: 'Enter an airport...'
    }
  },
  computed: {
    iata: {
      get () {
        return this.value.value ? this.value : null
      },
      set (val) {
        if (val) this.$emit('input', val)
      }
    }
  },
  methods: {
    searchAirports (terms) {
      return airports({ query: terms }).then((response) => {
        return response.data.map(airport => {
          return {
            label: airport.name,
            value: airport.iata,
            icon: 'local_airport',
            color: 'red-8',
            append: airport.iata
          }
        })
      })
    }
  }
}
</script>

<style>

</style>
