<template>
  <q-card
    class="bg-blue-8 mobilleo-fee"
  >
    <q-card-section>
      <div class="text-h6 text-weight-normal text-white">
        Mobilleo Fee
      </div>
    </q-card-section>
    <q-card-section>
      <div class="row q-col-gutter-sm">
        <div class="col">
          <money-field
            v-model="item.unit_price"
            dark
            label="Unit Price"
            color="white"
          />
        </div>
        <div class="col">
          <q-input
            value="Standard"
            color="white"
            dark
            readonly
            stack-label
            label="Tax Rate"
          />
        </div>
      </div>
    </q-card-section>
  </q-card>
</template>

<script>
import _ from 'lodash'
import { select, values as taxValues } from 'store/vat-rates'
import moneyField from 'components/MFields/money.vue'
import Money from 'js-money'
export default {
  components: {
    moneyField
  },
  props: {
    value: {
      type: Object,
      default: () => {}
    },
    index: Number
  },
  data () {
    return {
      item: _.cloneDeep(this.value),
      taxRates: select
    }
  },
  computed: {
    taxRate () {
      return taxValues[this.item.tax_rate]
    },
    unitPrice () {
      const lowestCommon = this.item.unit_price !== '' && this.item.unit_price !== null
        ? parseInt(this.item.unit_price)
        : 0
      const amount = Money.fromInteger(lowestCommon, Money[this.item.currency], Math.round)

      if (this.item.unit_price_includes_tax) {
        const vat = this.vatAmountFromGross(amount)
        return amount.subtract(vat)
      } else {
        return amount
      }
    },
    net () {
      return this.unitPrice.multiply(this.item.quantity, Math.round)
    },
    tax () {
      return (this.unitPrice.multiply(this.item.quantity)).multiply(this.taxRate, Math.round)
    },
    gross () {
      return this.net.add(this.tax)
    }
  },
  watch: {
    gross (val) {
      this.$emit('input', {
        ...this.item,
        net: this.net,
        tax: this.tax,
        gross: this.gross
      })
    }
  },
  methods: {
    vatAmountFromGross (nGrossAmount) {
      return nGrossAmount.divide((1 + (1 / this.taxRate)))
    }
  }
}
</script>
<style lang="stylus">
.mobilleo-fee .q-if-label
  color white!important
</style>
