<template>
  <form class="row">
    <div class="col-sm-12">
      <m-group-title label="Booking information" />
      <q-card class="bg-white">
        <q-card-section>
          <q-input
            v-model="booking.pickup_location.value"
            type="text"
            stack-label
            label="Pickup location"
          />

          <datetime-picker
            v-model="booking.pickup_datetime"
            label="Pick up time"
          />

          <q-input
            v-model="booking.dropoff_location.value"
            type="text"
            stack-label
            label="Dropoff location"
          />
        </q-card-section>
      </q-card>
    </div>
  </form>
</template>

<script>
import datetimePicker from './shared/datetime-picker'
import { MGroupTitle } from 'components/'

export default {
  name: 'BookingFormLocalLink',
  components: {
    datetimePicker,
    MGroupTitle
  },
  props: {
    value: Object
  },
  data () {
    return {
      booking: {
        content_type: {
          value: 'locallink',
          label: 'Local Link'
        },
        pickup_location: {
          value: null,
          label: null
        },
        pickup_datetime: {
          value: null,
          label: null
        },
        dropoff_location: {
          value: null,
          label: null
        },
        local_link_confirmation_number: {
          value: null,
          label: null
        }
      }
    }
  },
  watch: {
    booking: {
      deep: true,
      handler (val) {
        this.$emit('input', val)
      }
    }
  },
  created () {
    if (Object.keys(this.value).length) {
      Object.keys(this.value).forEach(key => {
        this.booking[key] = this.value[key]
      })
      this.booking.content_type = { label: null, value: 'locallink' }
    }
  }
}
</script>

<style lang="stylus" scoped>

</style>
