<template>
  <form class="row q-col-gutter-sm">
    <div class="col-sm-12">
      <m-group-title label="Booking information" />
      <q-card class="bg-white">
        <q-card-section>
          <q-input
            v-model="booking.name.value"
            type="text"
            stack-label
            label="Hotel Name"
          />

          <q-input
            v-model="booking.hotel_confirmation_number.value"
            type="text"
            stack-label
            label="Hotel Confirmation Number"
          />

          <q-input
            v-model="booking.room_type.value"
            type="text"
            stack-label
            label="Room Type"
          />

          <datetime-picker
            v-model="booking.check_in"
            label="Check In"
          />

          <datetime-picker
            v-model="booking.check_out"
            :min="minCheckout"
            label="Check Out"
          />
        </q-card-section>
      </q-card>
    </div>
  </form>
</template>

<script>
import datetimePicker from './shared/datetime-picker'
import { MGroupTitle } from 'components/'

export default {
  name: 'BookingFormHotel',
  components: {
    datetimePicker,
    MGroupTitle
  },
  props: {
    value: Object
  },
  data () {
    return {
      booking: {
        content_type: { label: null, value: 'hotel' },
        hotel_confirmation_number: {
          label: null,
          value: null
        },
        name: {
          label: null,
          value: null
        },
        room_type: {
          label: null,
          value: null
        },
        check_in: {
          label: null,
          value: null
        },
        check_out: {
          label: null,
          value: null
        }
      }
    }
  },
  computed: {
    minCheckout () {
      return this.booking.check_in.value ? this.booking.check_in.value : null
    }
  },
  watch: {
    booking: {
      deep: true,
      handler (val) {
        this.$emit('input', val)
      }
    }
  },
  created () {
    if (Object.keys(this.value).length) {
      Object.keys(this.value).forEach(key => {
        this.booking[key] = this.value[key]
      })
      this.booking.content_type = { label: null, value: 'hotel' }
    }
  }
}
</script>

<style lang="stylus" scoped>

</style>
