<template>
  <div class="col-sm-6">
    <q-card class="bg-white leg-card">
      <q-card-section class="title">
        {{ `Leg ${legNumber}` }}
        <q-btn dense flat class="delete float-right" type="button" label="delete leg" @click="removeLeg" />
      </q-card-section>
      <q-separator />
      <q-card-section>
        <iata-select
          v-model="leg.depart_iata"
          label="Depart From"
        />

        <datetime-picker
          v-model="leg.depart_at"
          label="Depart at"
        />

        <iata-select
          v-model="leg.arrive_iata"
          label="Land In"
        />

        <datetime-picker
          v-model="leg.arrive_at"
          label="Arrive at"
        />

        <q-input
          v-model="leg.flight_number.value"
          type="text"
          stack-label
          label="Flight Number"
        />

        <q-input
          v-model="leg.supplier_reference.value"
          type="text"
          stack-label
          label="Supplier Reference"
        />
      </q-card-section>
    </q-card>
  </div>
</template>

<script>
import datetimePicker from './datetime-picker'
import iataSelect from './iata-select'
export default {
  components: {
    datetimePicker,
    iataSelect
  },
  props: {
    value: Object,
    legNumber: Number
  },
  computed: {
    leg: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  },

  methods: {
    removeLeg () {
      this.$emit('removeLeg')
    }
  }
}
</script>

<style lang="stylus" scoped>
.delete
  float right

</style>
