<template>
  <div class="row q-col-gutter-sm">
    <template v-for="c in contentTypes">
      <div
        v-if="hasPermission('can.book.for.anyone') || hasPermission(`book.${c.value}`)"
        :key="c.value"
        class="col-xs-6 col-sm-3"
      >
        <q-card
          v-ripple
          :class="type === c.value ? `bg-${getTravelContentColor(c.value)} selected` : 'bg-white'"
          class="content-type"
          @click.native="() => type = c.value"
        >
          <q-card-section>
            <div class="row">
              <div class="col-xs-2 icon">
                <m-icons-type
                  :inverted="true"
                  :type="c.value"
                  size="16px"
                />
              </div>
              <div class="col-xs-10">
                <div class="text-faded text-h5">
                  {{ c.label }}
                </div>
              </div>
            </div>
          </q-card-section>
        </q-card>
      </div>
    </template>
  </div>
</template>

<script>
import authentication from 'mixins/authentication'
import travelContents from 'mixins/travelContents'
import { MIconsType } from 'components/'
export default {
  components: { MIconsType },
  mixins: [travelContents, authentication],
  props: {
    value: String
  },
  data () {
    return {
      contentTypes: [
        {
          label: this.$t('content_type.rail'),
          value: 'rail'
        },
        {
          label: this.$tc('content_type.flight'),
          value: 'flight'
        },
        {
          label: this.$tc('content_type.rental'),
          value: 'rental'
        },
        {
          label: this.$tc('content_type.hotel'),
          value: 'hotel'
        },
        {
          label: this.$tc('content_type.eurostar'),
          value: 'eurostar'
        },
        {
          label: this.$t('parking'),
          value: 'parking'
        },
        {
          label: this.$t('lounge.lounge'),
          value: 'lounge'
        },
        {
          label: this.$t('chauffeur'),
          value: 'chauffeur'
        },
        {
          label: this.$tc('content_type.taxi'),
          value: 'ridehailing'
        },
        {
          label: this.$t('content_type.carclub'),
          value: 'carclub'
        },
        {
          label: this.$t('content_type.onwardtravel'),
          value: 'onwardtravel'
        },
        {
          label: this.$t('content_type.bikehire'),
          value: 'bikehire'
        }
      ]
    }
  },
  computed: {
    type: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
.content-type
  position relative
  cursor pointer
.content-type.selected i, .content-type.selected h5
  color white!important
.text-h5
  font-size 115%
  width 100%
  text-align right

.icon
  font-size 36px!important
.content-type
  .row > div
    display flex
    align-items center

@media screen and (min-width: 1300px)
  .icon
    font-size 60px!important
  .content-type h5
    text-align right
</style>
