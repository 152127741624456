<template>
  <div>
    <div class="bg-image" />
    <div class="grid">
      <div v-if="!approvalHandled" class="box">
        <div class="title">
          {{ $t('booking_authorisation.quest_authorisation') }}
        </div>
        <q-separator />
        <div v-if="request && !loading" class="content">
          <div class="info">
            <div class="icon">
              <m-icons-avatar
                :type="request.content_type"
                size="xl"
              />
            </div>
            <div class="text">
              {{ request.reference }}
            </div>
          </div>
          <info icon="fas fa-user" :text="request.user ? request.user.display_name : 'Unknown'" />
          <info icon="fas fa-receipt" :text="request.price" />
          <info icon="fas fa-calendar-alt" :text="request.required_by.date | date" />
        </div>
        <div v-if="loading" class="content">
          <div v-for="i in 3" :key="i" class="info">
            <div class="icon">
              <q-skeleton type="QAvatar" size="xl" />
            </div>
            <div class="text">
              <q-skeleton type="text" />
            </div>
          </div>
        </div>
        <q-separator />
        <div class="actions">
          <q-btn
            v-if="!showRejectBox"
            color="primary"
            :label="$t('booking_authorisation.action_approve')"
            @click="submit(true)"
          />
          <q-btn
            color="white-secondary"
            :class="{ span: showRejectBox }"
            :label="showRejectBox ? $t('booking_authorisation.action_return') : $t('booking_authorisation.action_reject')"
            @click="showRejectBox = !showRejectBox"
          />
        </div>
        <div class="rejectReason" :class="{ showReject: showRejectBox }">
          <q-input v-model="rejectReason" type="textarea" placeholder="Reason for rejection" />
          <q-btn
            color="negative"
            :disable="!rejectReasonValid"
            :label="$t('booking_authorisation.action_rejectwithreason')"
            @click="submit(false)"
          />
        </div>
      </div>
      <div v-else class="box">
        <div v-if="previouslyHandled" class="title">
          {{ $t('booking_authorisation.handled') }}
        </div>
        <div v-else class="title">
          {{ $t(`booking_authorisation.${ approved ? 'approved' : 'rejected' }`) }}
        </div>
        <q-separator />
        <div class="content">
          <div class="subtle">
            {{ request.reference }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getAuthorisationDetails, authoriseRequest } from 'api/requests'
import { handleErrors } from 'utils/utils'
import { MIconsAvatar } from 'components/'
import Info from './info.vue'
import date from 'utils/date-time'
export default {
  components: {
    MIconsAvatar,
    Info
  },
  filters: {
    date (data) {
      return data ? date.toMediumDateTime(data) : null
    }
  },
  props: {
    reference: {
      type: String,
      required: true
    },
    token: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      loading: true,
      request: null,
      showRejectBox: false,
      rejectReason: '',
      approveEndpoint: '',
      rejectEndpoint: '',
      approved: false,
      approvalHandled: false,
      previouslyHandled: false
    }
  },
  computed: {
    signature () {
      return this.$route.query.signature
    },
    expires () {
      return this.$route.query.expires
    },
    rejectReasonValid () {
      return true // TODO: Decide with Paul if we want to enforce a reason for rejection
    }
  },
  created () {
    this.loading = true
    getAuthorisationDetails(this.reference, this.token, {
      expires: this.expires,
      signature: this.signature
    })
      .then(({ data: { request, approve_endpoint, reject_endpoint } }) => {
        this.request = request.data
        this.approveEndpoint = approve_endpoint
        this.rejectEndpoint = reject_endpoint

        // Need to confirm this, but it seems so far this is the best way of checking if an approval has been handled
        if (this.request.status !== 'NEW') {
          this.approvalHandled = true
          this.previouslyHandled = true
        }
      })
      .catch(error => {
        handleErrors(error)
      })
      .finally(() => {
        this.loading = false
      })
  },
  methods: {
    submit (approve) {
      if (approve === undefined) return

      this.approved = approve

      authoriseRequest(approve ? this.approveEndpoint : this.rejectEndpoint, this.rejectReason)
        .then(() => {
          this.approvalHandled = true
        })
        .catch(error => {
          handleErrors(error)
        })
    }
  }
}
</script>
<style lang="stylus" scoped>
.bg-image
  &:before
    content ''
    position fixed
    top 0
    left 0
    right 0
    bottom 0
    background-image url('~assets/Scooter.png')
    background-repeat no-repeat
    background-size cover
    background-position center center
    filter blur(20px)
  &:after
    content ''
    position absolute
    top 0
    left 0
    right 0
    bottom 0
    background-color rgba($primary, 0.5)

.grid
  position fixed
  top 0
  left 0
  right 0
  bottom 0
  display grid
  grid-template-columns max-content
  justify-content center
  align-items center

  .box
    display grid
    gap 0.5rem
    border-radius 4px
    background-color #fafafa
    box-shadow 0 0.5rem 1rem rgba(0, 0, 0, 0.1)
    justify-content center
    align-items center

    .title
      padding 8px
      font-size 1.75em
      color $primary

    .content
      padding 8px
      font-size 1em
      display grid
      grid-template-columns max-content max-content
      gap 1rem
      justify-content space-between

      .info
        display grid
        grid-template-columns auto 1fr
        align-items center
        gap 0.5rem
        grid-template-areas "icon text"
        &:nth-child(even)
          grid-template-columns 1fr auto
          grid-template-areas "text icon"
          .text
            text-align right

          .icon
            grid-area icon

          .text
            grid-area text

    .actions
      padding 8px
      display grid
      grid-template-columns 1fr 1fr
      gap 0.5em

      .span
        grid-column span 2

    .rejectReason
      display grid
      overflow hidden
      max-height 0
      transition 0.3s ease-in
      &.showReject
        padding 8px

    .showReject
      max-height 500px

  .subtle
    color darken(#fff, 50%)
    font-size 0.75em
    font-style italic
</style>
