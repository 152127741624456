<template>
  <q-card class="purchase-invoice bg-white">
    <q-card-section>
      <h6> Invoice {{ value.number }}</h6>
      <div class="row q-col-gutter-sm">
        <div class="col-xs-1">
          <label>Quantity</label>
        </div>
        <div class="col-xs-3">
          <label>Description</label>
        </div>
        <div class="col-xs-1">
          <label>Unit Price</label>
        </div>
        <div class="col-xs-2">
          <label>Tax Rate</label>
        </div>
        <div class="col">
          <label>Net Amount</label>
        </div>
        <div class="col">
          <label>Tax Amount</label>
        </div>
        <div class="col">
          <label>Gross Amount</label>
        </div>
      </div>
      <div class="items">
        <read-only-item
          v-for="(it, i) in value.items"
          :key="it.description"
          :value="it"
          :index="i"
        />
      </div>
      <q-separator style="margin-top:15px; margin-bottom: 15px;" />
      <div class="totals row q-col-gutter-sm">
        <div class="col-xs-7">
          Totals
        </div>
        <div class="col">
          {{ value.net_amount.text }}
        </div>
        <div class="col">
          {{ value.tax_amount.text }}
        </div>
        <div class="col">
          {{ value.gross_amount.text }}
        </div>
      </div>
    </q-card-section>
  </q-card>
</template>

<script>
import readOnlyItem from './invoice-item-read'
export default {
  components: {
    readOnlyItem
  },
  props: ['value']
}
</script>

<style lang="stylus" scoped>
h6
  margin-bottom 15px
</style>
