<template>
  <div class="notes">
    <div class="notes-title">
      <m-group-title
        class="title"
        label="Notes"
        columns="null"
      />
      <q-btn
        flat
        label="Add Note"
        class="note-add"
        @click="create = true"
      />
    </div>
    <m-resource-notes
      :notes="sortedNotes"
      :fab="false"
      :booking="booking"
    />

    <q-dialog
      v-model="create"
      content-class="editor-dialog"
    >
      <q-card style="width: 700px; max-width: 80vw; height: auto; max-height: 50vh;">
        <q-card-section>
          <form>
            <m-editor v-model="draft.body" class="fit" />
          </form>
        </q-card-section>
        <q-card-actions align="right">
          <q-btn v-close-popup color="primary" label="Create" @click.stop="addNote()" />
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import _ from 'lodash'
import date from 'utils/date-time'
import { MGroupTitle, MResourceNotes, MEditor } from 'components/'

export default {
  components: { MGroupTitle, MResourceNotes, MEditor },
  props: {
    booking: {
      type: Object,
      default: null
    },
    notes: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      create: false,
      draft: {
        body: ''
      }
    }
  },
  computed: {
    sortedNotes () {
      const sortedNotes = _.orderBy(this.notes, n => date.newDate(n.created_at.date), 'desc')

      return sortedNotes
    }
  },
  methods: {
    addNote () {
      this.$store.dispatch('requests/addNote', {
        request: this.$route.params.reference,
        // Grasping at straws as to why the below value gets set to empty string before it gets sent to the API (in staging)
        note: _.cloneDeep(this.draft)
      })
        .then(note => {
          this.clearDraft()
        })
    },
    clearDraft () {
      this.draft.body = ''
    }
  }
}
</script>

<style lang="stylus" scoped>
.notes
  position relative
.notes-title
  display flex
  justify-content space-between

.note-add
  position absolute
  top -15px
  right 0

</style>

<style lang="stylus">
.editor-dialog .q-card__section
  padding 0
.editor-dialog .modal-buttons
  padding 15px
.editor-dialog .modal-scroll
  max-height 500px
.ql-toolbar.ql-snow
  border-top 0!important
  border-right 0!important
  border-left 0!important
  padding 1rem 2rem
.editor-dialog .ql-container
  border-right 0!important
  border-left 0!important
.editor-dialog .ql-editor
  min-height 150px
  max-height 200px
  overflow auto
.editor-wrapper
  min-height 200px
svg
  display block
  margin auto
.labelled-btn
  margin-top 15px
</style>
