<template>
  <form class="request-form row q-col-gutter-sm">
    <div class="col-sm-12">
      <m-group-title label="Customer information" />
      <q-card class="bg-white">
        <q-card-section>
          <div class="row q-col-gutter-sm">
            <div class="col-sm-4">
              <requester-field
                v-model="request.requester"
              />
            </div>
            <div class="col-sm-4">
              <user-field
                v-model="request.user"
                :disable="!request.requester || request.requester.organisation === 'Individual' ? true : false"
                :organisation="request.requester ? request.requester.organisation : null"
              />
            </div>
            <div class="col-sm-4">
              <booking-field
                v-model="request.booking"
                :user="request.user"
                :disable="!request.user"
              />
            </div>
            <div class="col-xs-12 col-sm-4">
              <q-select
                v-model="request.preferred_payment_method"
                :disable="paymentMethods.length < 2 ? true : false"
                :options="paymentMethods"
                label="Preferred Payment Method"
                map-options
                emit-value
              />
            </div>
            <div class="col-sm-4">
              <q-input
                v-model="request.contact_number"
                type="tel"
                label="Contact Number"
              />
            </div>
            <div class="col-sm-4">
              <q-input
                v-model="request.contact_email"
                type="email"
                label="Contact Email"
              />
              <div class="col-sm-4">
                <date-picker
                  v-model="request.required_by"
                  label="Required By"
                />
              </div>
            </div>
          </div>
        </q-card-section>
      </q-card>
    </div>
    <m-group-title label="Request information" />
    <div class="col-sm-12">
      <q-card class="bg-white">
        <m-editor v-model="request.message" class="editor" />
      </q-card>
    </div>
    <q-page-sticky
      position="bottom-right" :offset="[30, 30]"
    >
      <q-btn
        round
        type="button"
        size="lg"
        color="positive"
        icon="assignment_turned_in"
        @click="openDialog"
      />
    </q-page-sticky>
    <confirmation-modal v-model="dialog" :request="request" type="amend" />
  </form>
</template>

<script>
import _ from 'lodash'
import requesterField from './shared/requester-field'
import userField from './shared/user-field'
import confirmationModal from './shared/confirmation-modal'
import bookingField from './shared/booking-field'
import datePicker from './shared/date-picker'
import shapeUser from './shared/shape-user.js'
import { MGroupTitle, MEditor } from 'components/'

export default {
  components: {
    MGroupTitle,
    MEditor,
    requesterField,
    userField,
    confirmationModal,
    bookingField,
    datePicker
  },
  props: {
    filledRequester: Object
  },
  data () {
    return {
      dialog: false,
      request: {
        type: 'amendment',
        requester: null,
        user: null,
        message: null,
        booking: null,
        required_by: new Date(),
        contact_email: null,
        contact_number: null,
        preferred_payment_method: null
      },
      paymentMethods: []
    }
  },
  watch: {
    'request.requester' (val) {
      this.request.user = null
      this.setAllowedPaymentMethods()
      if (val.organisation === 'Individual') {
        this.request.user = val
        this.request.preferred_payment_method = 'card'
      }
      this.request.booking = null
    },
    'requester.user' (val) {
      if (!val) this.request.booking = null
    }
  },
  created () {
    if (this.filledRequester) {
      this.request.requester = shapeUser(this.filledRequester)
    }
  },
  methods: {
    setAllowedPaymentMethods () {
      const permissions = this.request.requester.permissions
      const accountPermission = _.find(permissions, p => p === 'payments.account')

      const paymentMethods = [
        {
          label: 'Card',
          value: 'card'
        }
      ]

      if (accountPermission) {
        paymentMethods.push({
          label: 'Account',
          value: 'account'
        })
      }
      this.paymentMethods = paymentMethods
      this.request.preferred_payment_method = 'card'
    },
    openDialog () {
      this.dialog = true
    }
  }
}
</script>

<style lang="stylus">
.editor .ql-editor
  min-height 200px
</style>
