<template>
  <div v-if="show">
    <q-separator class="hr" />
    <div v-if="!complete" style="padding: 16px 0">
      <action-dropdown
        :label="item.label"
        @primaryAction="setState"
        @close="close"
      >
        <slot />
      </action-dropdown>
      <sub>{{ item.info }}</sub>
    </div>
    <div v-else>
      <sub style="display: block; padding: 16px 0;">
        <strong @click="setState">{{ item.label }}</strong><br>
        {{ item.done }}
      </sub>
    </div>
  </div>
</template>

<script>
import actionDropdown from './action-dropdown.vue'
export default {
  components: { actionDropdown },
  props: ['item', 'show', 'complete'],
  methods: {
    setState () {
      this.$emit('setState', this.item.state)
    },
    close () {
      this.$emit('closeRequest')
    }
  }
}
</script>

<style lang="stylus" scoped>
.active
  background aliceblue
strong
  display inline-block
  border-bottom 1px solid black
  margin auto
  margin-bottom 10px
  text-align center
  cursor pointer
</style>
