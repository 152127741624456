<template>
  <div class="info">
    <div class="icon">
      <q-avatar v-bind="{ icon, color }" size="xl" />
    </div>
    <div class="text">
      {{ text }}
    </div>
  </div>
</template>
<script>
export default {
  props: {
    icon: {
      type: String,
      default: 'fas fa-info'
    },
    color: {
      type: String,
      default: 'primary'
    },
    text: {
      type: String,
      default: 'Unknown'
    }
  }
}
</script>
