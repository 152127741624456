<template>
  <q-page>
    <div class="layout-padding booking-form-container">
      <div class="col-sm-12">
        <note
          v-model="note"
        />
      </div>

      <div class="col-sm-12">
        <invoice
          v-model="invoiceItems"
          type="sales"
        />
      </div>

      <div class="col-sm-12">
        <purchase-invoices
          :invoices="request.invoices || []"
          class="purchase-invoices"
        />
      </div>

      <submit-cancellation
        :booking-reference="$route.params.booking"
        :invoice-items="invoiceItems"
        :note="note"
      />
    </div>
  </q-page>
</template>

<script>
import store from 'store'
import { mapGetters } from 'vuex'
import http from 'api/http'

import invoice from '../../invoice/invoice'
import purchaseInvoices from '../../view/invoices'
import note from '../forms/shared/note'
import submitCancellation from '../forms/shared/submit-cancellation'

export default {
  components: {
    invoice,
    purchaseInvoices,
    note,
    submitCancellation
  },
  data () {
    return {
      booking: {},
      invoiceItems: [],
      note: ''
    }
  },
  beforeRouteEnter (to, from, next) {
    Promise.all([
      http.get(`/bookings/${to.params.booking}?include=notes`),
      store.dispatch('requests/loadResource', {
        id: to.params.reference
      })
    ])
      .then(([res]) => {
        next(vm => {
          vm.booking = res.data
        })
      })
  },
  computed: {
    ...mapGetters({
      getRequest: 'requests/getResource'
    }),
    request () {
      return this.getRequest(this.$route.params.reference)
    }
  }
}
</script>

<style lang="stylus" scoped>
.booking-form-container
  height calc(100vh - 64px)
  overflow: auto
h4
  margin-bottom 20px
</style>
